export const environment:any={
	production:false,
	google:{
		apiKey:'AIzaSyCJFLo3Ogkjop0zgvIFWPEWNdvgqLM3p5w',
		recaptcha3SiteKey:'6LfHSsgjAAAAANr8kAtOGB2x1cIq1FtrIW-Cc4XA'
	},
	firebase:{
		apiKey:'AIzaSyCSchVW8RMCrsu5su7SpKyRSo5tP9W_XNk',
		authDomain:'bendita-grow-test-373015.firebaseapp.com',
		projectId:'bendita-grow-test-373015',
		storageBucket:'bendita-grow-test-373015.appspot.com',
		messagingSenderId:'351567411374',
		appId:'1:351567411374:web:aa0fde6225c19102c3153f',
		measurementId:'G-MHPE9PRM5W'
	},
	apiServer:{
		url:'https://api.test.benditagrow.com'
	},
	contact:{
		phone:'+573135751807',
		instagram:'https://instagram.com/growbendita',
		facebook:'https://facebook.com/BenditaGrow',
		twitter:'https://twitter.com'
	},
	paypal:{
		clientId:'AcuwEvLqzyXYXycifdmlvcMyHer-4B70BDCghRSri_lXzISjpKWTZZwU9Xzf_OCW_7q8KCcg9NRj3kcX'
	},
	mercadopago:{
		publicKey:'TEST-dd6c9f11-0fab-43a1-b193-97e3a2bbc2f3'
	}
};




